export function submitQuizResultsToToKlaviyo({
  email,
  quizAnswers,
  quizRecommendedProductHandles,
}: {
  email: string
  quizAnswers: {
    [questionHandle: string]: string[]
  }
  quizRecommendedProductHandles: string[] | undefined
}) {
  return fetch(
    `https://a.klaviyo.com/client/subscriptions/?company_id=${process.env.NEXT_PUBLIC_KLAVIYO_SITE_ID}`,
    {
      method: 'POST',
      headers: { revision: '2025-01-15', 'content-type': 'application/json' },

      body: JSON.stringify({
        data: {
          type: 'subscription',
          attributes: {
            profile: {
              email,
              properties: {
                'quiz:recommended-products':
                  quizRecommendedProductHandles?.join(','),
                ...Object.entries(quizAnswers).reduce(
                  (cur, [questionHandle, answers]) => ({
                    ...cur,
                    [`quiz:${questionHandle}`]: answers.join(','),
                  }),
                  {} as Record<string, string>
                ),
              },
            },
            subscriptions: {
              email: ['MARKETING'],
            },
            list_id: 'WpZizQ', // TODO configure from contentful
            custom_source: 'Quiz Response',
          },
        },
      }),
    }
  )
}
